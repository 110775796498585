import React, {useState,useContext} from 'react';

import {Link} from 'react-router-dom';

import '../../App.css';
import {MyContext} from '../login/MyContext';

import LogoMenu from '../assets/plantilla/menu/servidor.gif';
import LogoUsuario from '../assets/plantilla/menu/user2-160x160.jpg';
import Usuarios from '../assets/plantilla/menu/usuarios.png';
import Crear_usuarios from '../assets/plantilla/menu/crear_usuarios.png';
import Ver_usuarios from '../assets/plantilla/menu/ver_usuarios.png';
import Compras from '../assets/plantilla/menu/compras.png';
import Ingresos from '../assets/plantilla/menu/ingresos.png';
import Proveedores from '../assets/plantilla/menu/proveedores.png';
import Ventas from '../assets/plantilla/menu/ventas.png';
import Ventas_submenu from '../assets/plantilla/menu/ventas_submenu.png';
import Clientes from '../assets/plantilla/menu/clientes.png';
import Inventarios from '../assets/plantilla/menu/inventarios.png';
import Categorias from '../assets/plantilla/menu/categorias.png';
import Articulos from '../assets/plantilla/menu/articulos.png';
import Reportes from '../assets/plantilla/menu/reportes.png';
import Reportes_submenu from '../assets/plantilla/menu/reportes_submenu.png';
import Kardex from '../assets/plantilla/menu/kardex.png';

function Menu(props) {
  const {rootState,logoutUser} = useContext(MyContext);

  
  const [dropdown, setDropdown]=useState(false);
  const {isAuth,theUser,showLogin} = rootState;

  const abrirCerrarDropdown=()=>{
    setDropdown(!dropdown);
  }
    
  return (
    <nav className="navbar  main-sidebar sidebar-light-primary elevation-4 " >
  
  
   <center>
  <a href="#" className="brand-link">
      <img src={LogoMenu} alt="ODIN" width="50px" />
      <span className="brand-text font-weight-light"></span>
    </a>   </center>  

   
    <div className=" scrollb ">
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        
        <div className="info">
          <br/>
          <h6  className="d-block"><b>{theUser.name}</b></h6>

          
          <button className="btn btn-danger " onClick={logoutUser} > Salir <i className="fas fa-sign-out-alt"></i></button>
            
            
            
        </div>
      </div>

      <nav className="mt-2">
    
   
      <ul className="nav navbar-nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">         


{ theUser.rol =="usuario"
          ?<><li className="nav-item" >
          <Link to="/Shoping" className="nav-link">
          
          <i class="fa fa-shopping-cart fa-2x" style={{color:"#14CF01"}}></i>
            <p><font size="3">
               &nbsp; Shoping
               </font>
             <b className="caret" />
           </p>
            </Link>
        </li>

        <li className="nav-item" >
          <Link to="/Productos" className="nav-link">
          
          <i class="fa fa-server fa-2x" style={{color:"#17A6FB"}}></i>
            <p><font size="3">
               &nbsp; Mis productos
               </font>
             <b className="caret" />
           </p>
            </Link>
        </li>
          
          <li className="nav-item" >
          <Link to="/Odin" className="nav-link">
          
          <i class="fas fa-cog fa-spin fa-2x" style={{color:"#17A6FB"}}></i>
            <p><font size="3">
               &nbsp; ODÍN IA
               </font>
             <b className="caret" />
           </p>
            </Link>
        </li>

        <li className="nav-item" >
          <Link to="/Esquemas" className="nav-link">
          
          <i class="fa fa-database fa-2x" aria-hidden="true" style={{color:"#F9900B"}}></i>
            <p><font size="4">
               &nbsp; Esquemas
               </font>
             <b className="caret" />
           </p>
            </Link>
        </li>

        <li className="nav-item" >
          <Link to="/Proyectos" className="nav-link">
          
          <i class="fa fa-file-code-o fa-2x" aria-hidden="true" style={{color:"#F9900B"}}></i>
            <p><font size="4">
               &nbsp; Proyectos
               </font>
             <b className="caret" />
           </p>
            </Link>
        </li>

        <li className="nav-item" >
          <Link to="/Clones" className="nav-link">
          
          <i class="fa fa-cubes fa-2x" aria-hidden="true" style={{color:"#F9900B"}}></i>
            <p><font size="4">
               &nbsp; Clones
               </font>
             <b className="caret" />
           </p>
            </Link>
        </li>


        <li className="nav-item" >
          <Link to="/Manuales" className="nav-link">
          
          <i class="fa fa-file-pdf-o fa-2x" aria-hidden="true" style={{color:"#F9900B"}}></i>
            <p><font size="4">
               &nbsp; Manuales
               </font>
             <b className="caret" />
           </p>
            </Link>
        </li>

   

    </>

: ""
}







      </ul>
      

     

      <ul className="nav navbar-nav nav-pills nav-sidebar flex-column">
      
        
      </ul>
    
    </nav>
  </div>

</nav>
       
    )

    
  }

 


export default Menu